import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faPaperPlane, faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/layout"
import {
  contactSuccess,
  contactSuccess__icon,
  contactSuccess__text,
} from "../sass/success.module.scss"

library.add(faPaperPlane, faCheckCircle)

const Success = () => (
  <Layout>
    <div className={contactSuccess}>
      <FontAwesomeIcon
        className={contactSuccess__icon}
        icon="paper-plane"
        size="5x"
      />
      <div className={contactSuccess__text}>
        {`Thank you for reaching out.
               I will try to to respond as soon as possible.
  
  - Guillaume`}
      </div>
    </div>
  </Layout>
)

export default Success
